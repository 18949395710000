/* eslint-disable @typescript-eslint/ban-types */
import { select } from 'd3-selection';
import { geoPath, geoMercator, GeoProjection } from 'd3-geo';
import { Feature } from 'geojson';
import type { Geometry, GeoJsonProperties, FeatureCollection } from 'geojson';
import _ from 'lodash';

import { appendIfNotExists } from '@/components/helpers';
import getTailwindColor from '@/getTailwindColor';

export type ABMIGeo = FeatureCollection<Geometry, GeoJsonProperties>;
export type FlyoutHorzPos = 'left' | 'right' | undefined;
export type FlyoutVertPos = 'top' | 'center' | 'bottom' | undefined;
export type Point = [number, number];
export type Coordinate = [Point, Point];
export type Dimensions = { width: number, height: number };

export type FeaturesOptions = {
    province: ABMIGeo;
    region: ABMIGeo;
    area?: ABMIGeo;
}

export type DrawOptions = {
    flyoutPosition: [FlyoutHorzPos, FlyoutVertPos];
    showAreas: boolean;
    showZoominMap: boolean,
    highlightColor: string | undefined;
    fillColor: string | undefined;
    onAreaClick: Function | undefined;
}

const padding = { top: 5, right: 0, bottom: 10, left: 0 };
const mercatorPrecision = 100;
const maxFlyoutRation = 0.09; // =0 mean never has flyout // 0.09;
const provinceMapClass = 'province-map';
const regionMapClass = 'region-map';
const areaMapClass = 'area-map';
const linesClass = 'lines';

function getProvinceDimensions(
    svg: Element | null,
): Dimensions {
    const regionSel = select(svg);
    const provinceMapD3 = regionSel.select(`.${provinceMapClass}`);
    const provinceBox = (provinceMapD3.selectAll('.province').node() as SVGGraphicsElement)?.getBBox();

    return {
        width: provinceBox.width,
        height: provinceBox.height,
    };
}

function getProvinceCoords(
    svgDimensions: Dimensions,
    regionDimensions: Dimensions,
    flyoutHorzPos: FlyoutHorzPos
): Coordinate {
    let regionX0 = padding.left;
    let regionX1 = svgDimensions.width - padding.right;
    const regionY0 = padding.top;
    const regionY1 = svgDimensions.height - padding.bottom;

    if (flyoutHorzPos === 'left') {
        regionX0 = svgDimensions.width - regionDimensions.width + padding.left;
    } else if (flyoutHorzPos === 'right') {
        regionX1 = regionDimensions.width - padding.right;
    }

    return [[regionX0, regionY0], [regionX1, regionY1]];
}

function getAreaCoords(
    svgDimensions: Dimensions,
    regionDimensions: Dimensions,
    areaDimensions: Dimensions,
    flyoutHorzPos: FlyoutHorzPos,
    flyoutVertPos: FlyoutVertPos,
): Coordinate {
    let areaX0 = padding.left;
    let areaX1 = areaDimensions.width - padding.right;
    if (flyoutHorzPos === 'right') {
        areaX0 = regionDimensions.width + padding.left;
        areaX1 = svgDimensions.width - padding.right;
    }

    let areaY0 = padding.top;
    let areaY1 = areaDimensions.height - padding.bottom;
    if (flyoutVertPos === 'center') {
        areaY0 = (areaDimensions.height / 2) + padding.top;
        areaY1 = svgDimensions.height - (areaDimensions.height / 2) - padding.bottom;
    } else if (flyoutVertPos === 'bottom') {
        areaY0 = svgDimensions.height - areaDimensions.height + padding.top;
        areaY1 = svgDimensions.height - padding.bottom;
    }

    return [[areaX0, areaY0], [areaX1, areaY1]];
}

function getProvinceProj(
    province: ABMIGeo,
    svgDimensions: Dimensions,
    regionDimensions: Dimensions,
    flyoutHorzPos: FlyoutHorzPos
): GeoProjection {
    const provinceMapBox = getProvinceCoords(svgDimensions, regionDimensions, flyoutHorzPos);

    return geoMercator()
        .precision(mercatorPrecision)
        .fitExtent(provinceMapBox, { type: 'FeatureCollection', features: province.features });
}

function getRegionProj(provinceProj: GeoProjection): GeoProjection {
    return geoMercator()
        .precision(mercatorPrecision)
        .scale(provinceProj.scale())
        .center(provinceProj.center())
        .translate(provinceProj.translate());
}

function drawProvince(
    svg: Element | null,
    province: ABMIGeo,
    provinceProj: GeoProjection,
): void {
    const provinceFeatures = province.features;

    const regionSel = select(svg);
    const provinceMapD3 = appendIfNotExists<SVGGElement>(regionSel, 'g', provinceMapClass);
    provinceMapD3.selectAll('*').remove();

    provinceMapD3.selectAll('path')
        .data(provinceFeatures, (d) => (d as Feature).properties?.id || 'none')
        .join('path')
        .attr('d', geoPath().projection(provinceProj))
        .attr('class', 'province')
        .attr('pointer-events', 'none');
}

function drawRegions(
    svg: Element | null,
    region: ABMIGeo,
    area: ABMIGeo | undefined,
    regionProj: GeoProjection,
    showAreas: DrawOptions['showAreas'],
    highlightColor: DrawOptions['highlightColor'],
    fillColor: DrawOptions['fillColor'],
    onAreaClick: DrawOptions['onAreaClick']
): void {
    const regionFeatures = region.features;
    const areaFeatures = area?.features;

    const regionSel = select(svg);
    const regionMapD3 = appendIfNotExists<SVGGElement>(regionSel, 'g', regionMapClass);
    regionMapD3.selectAll('*').remove();

    const regionPath = geoPath().projection(regionProj);
    regionMapD3.selectAll<SVGGElement, Feature<Geometry, GeoJsonProperties>>('path')
        .data(regionFeatures, (d: Feature) => d.properties?.id || 'none')
        .join('path')
        .attr('d', regionPath)
        .attr('class', (d: Feature) => {
            let className = 'area';
            if (areaFeatures && d.properties?.id === areaFeatures[0].properties?.id) {
                className = `${className} selected`;
            }

            return className;
        })
        .attr('stroke-width', () => (showAreas ? '1' : '0'))
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .attr('fill', (d: Feature) => {
            if (areaFeatures && d.properties?.id === areaFeatures[0].properties?.id) {
                return highlightColor || getTailwindColor('gray', 500);
            }
            const AthRegionId = 65;
            const MineableRegionId = 67;
            // TODO: use generic approach to fix
            // both are subregions but mineable is completely within ath region, so don't use fill, otherwise like a whole in
            // the Ath region
            if (areaFeatures && areaFeatures[0].properties?.id === AthRegionId && d.properties?.id === MineableRegionId) {
                return 'none';
            }

            if (!showAreas) {
                return 'none';
            }
            return fillColor || 'white';
        })
        .on('click', (e, d) => {
            if (onAreaClick) {
                onAreaClick(e, d);
            }
        })
        .on('mouseenter', (e, hoverD) => {
            if (!showAreas) {
                return;
            }

            regionMapD3.selectAll<SVGGElement, Feature<Geometry, GeoJsonProperties>>('path')
                .classed('hovered', d => d.properties?.id === hoverD.properties?.id);
        });

    regionMapD3.on('mouseout', () => {
        if (!showAreas) {
            return;
        }

        regionMapD3.selectAll('path')
            .classed('hovered', false);
    });
}

function drawArea(
    svg: Element | null,
    area: ABMIGeo | undefined,
    provinceProj: GeoProjection,
    svgDimensions: Dimensions,
    regionDimensions: Dimensions,
    areaDimensions: Dimensions,
    flyoutHorzPos: FlyoutHorzPos,
    flyoutVertPos: FlyoutVertPos,
    showZoominMap: Boolean,
    highlightColor: DrawOptions['highlightColor']
): void {
    const areaFeatures = area?.features;

    const regionSel = select(svg);

    const regionMapD3 = appendIfNotExists<SVGGElement>(regionSel, 'g', regionMapClass);

    const linesD3 = appendIfNotExists<SVGGElement>(regionSel, 'g', linesClass);
    linesD3.selectAll('*').remove();

    const areaMapD3 = appendIfNotExists<SVGGElement>(regionSel, 'g', areaMapClass);
    areaMapD3.selectAll('*').remove();

    const areaBox = (regionMapD3.selectAll('.area.selected').node() as SVGGraphicsElement)?.getBBox() || false;

    const provinceBox = getProvinceDimensions(svg);

    const needsFlyout = areaBox && (areaBox.width * areaBox.height) / (provinceBox.width * provinceBox.height) < maxFlyoutRation;
    const hasFlyout = flyoutHorzPos && flyoutVertPos;

    if (!areaFeatures) {
        return;
    }
    if (!showZoominMap || (!hasFlyout || !needsFlyout)) {
        return;
    }

    let coordinates: Point[] = [];
    if (areaFeatures[0].geometry.type === 'Polygon') {
        coordinates = (areaFeatures[0].geometry).coordinates[0] as Point[];
    } else if (areaFeatures[0].geometry.type === 'MultiPolygon') {
        coordinates = (areaFeatures[0].geometry.coordinates as Point [][][]).reduce((points1: Point[], level1: Point[][]) => level1.reduce((points2: Point[], level2: Point[]) => level2.reduce((points3: Point[], level3: Point) => {
            points3.push(level3);
            return points3;
        }, points2), points1), []);
    }

    const minX: number = _.min(coordinates.map((coords: number[]) => coords[0])) || 0;
    const maxX: number = _.max(coordinates.map((coords: number[]) => coords[0])) || 0;
    const minY: number = _.min(coordinates.map((coords: number[]) => coords[1])) || 0;
    const maxY: number = _.max(coordinates.map((coords: number[]) => coords[1])) || 0;
    const center: Point = [(minX + maxX) / 2, (minY + maxY) / 2];

    const areaMapBox = getAreaCoords(
        svgDimensions,
        regionDimensions,
        areaDimensions,
        flyoutHorzPos,
        flyoutVertPos,
    );

    const areaProj = geoMercator()
        .precision(mercatorPrecision)
        .center(center)
        .fitExtent(areaMapBox, { type: 'FeatureCollection', features: areaFeatures });

    if (needsFlyout) {
        areaMapD3.selectAll('path')
            .data(areaFeatures, (d) => (d as Feature).properties?.id || 'none')
            .join('path')
            .attr('d', geoPath().projection(areaProj))
            .attr('class', 'area-zoom')
            .attr('fill', () => highlightColor || getTailwindColor('gray', 500));
    }

    // if (!area.value?.subRegion && areaFeatures[0].geometry.type === 'Polygon') {
    let lineTopCoords: Point = coordinates[0];
    let lineBottomCoords: Point = coordinates[0];

    for (let i = 0; i < coordinates.length; i++) {
        const point = coordinates[i];
        // highest point
        if (point[1] <= lineTopCoords[1]) {
            lineTopCoords = point;
            if (point[0] >= lineBottomCoords[0]) {
                lineTopCoords[0] = point[0];
            }
        }

        // lowest point
        if (point[1] >= lineBottomCoords[1]) {
            lineBottomCoords = point;
            if (point[0] >= lineBottomCoords[0]) {
                lineBottomCoords[0] = point[0];
            }
        }
    }

    // draw connector lines
    const regionProj = getRegionProj(provinceProj);
    [
        [areaProj(lineTopCoords), regionProj(lineTopCoords)],
        [areaProj(lineBottomCoords), regionProj(lineBottomCoords)]
    ].forEach((line) => {
        if (!line[0] || !line[1]) {
            return;
        }

        linesD3.append('line')
            .attr('x1', line[0][0])
            .attr('y1', line[0][1])
            .attr('x2', line[1][0])
            .attr('y2', line[1][1])
            .attr('class', 'line');
    });
}

export function draw(
    svg: Element | null | undefined,
    svgDimensions: Dimensions,
    features: FeaturesOptions,
    options: DrawOptions,
): void {
    if (!svg || !features.province || !features.region) {
        return;
    }

    const { flyoutPosition, showAreas, showZoominMap, highlightColor, fillColor, onAreaClick } = options;
    const [flyoutHorzPos, flyoutVertPos] = flyoutPosition;

    const regionDimensions: Dimensions = {
        width: svgDimensions.width * (showZoominMap ? 0.5 : 0.8),
        height: svgDimensions.height
    };
    const areaDimensions: Dimensions = {
        width: svgDimensions.width * 0.5,
        height: svgDimensions.height * 0.5,
    };

    const provinceProj = getProvinceProj(
        features.province,
        svgDimensions,
        regionDimensions,
        flyoutHorzPos
    );
    const regionProj = getRegionProj(provinceProj);

    drawProvince(
        svg,
        features.province,
        provinceProj
    );

    drawRegions(
        svg,
        features.region,
        features.area,
        regionProj,
        showAreas,
        highlightColor,
        fillColor,
        onAreaClick
    );

    drawArea(
        svg,
        features.area,
        provinceProj,
        svgDimensions,
        regionDimensions,
        areaDimensions,
        flyoutHorzPos,
        flyoutVertPos,
        showZoominMap,
        highlightColor,
    );
}
